function formatMonetaryValue(number) {
  if (number !== null && number !== undefined && number !== false) {
    return formatNumber(number);
  }
}

function formatMonetaryValueWithWarning(number) {
  if (number) {
    return formatNumber(number);
  }
}

function formatNumber(number) {
  let n = number.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (n === "-0.00") {
    n = "0.00";
  }
  return "$ " + n;
}
//Format monetary value no space after "$"
function formatMonetaryValueNoWhitespace(number) {
  if (number !== null && number !== undefined && number !== false) {
    return formatNumberNoWhiteSpace(number);
  }
}
function formatNumberNoWhiteSpace(number) {
  let n = number.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (n === "-0.00") {
    n = "0.00";
  }
  return "$" + n;
}
//Format value - unrounded(slice after two decimals)
function formatMonetaryValueUnrounded(number) {
  if (number !== null && number !== undefined && number !== false) {
    return formatNumberUnrounded(number);
  }
}

function formatNumberUnrounded(number) {
  let n = Math.trunc(number * 100) / 100;
  return formatNumber(n);
}
//----------------------------
function formatDate(date) {
  if (date) {
    if (date.length > 10) {
      date = date.substring(0, 10);
    }
    let d = new Date(date);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "UTC"
    };

    let dateString = d.toLocaleString("en-US", options);
    return dateString.replaceAll("/", "-");
  }
}

// Show date in format MONTH DD, YYYY
function formatDateTextMonth(dateTime) {
  let date = new Date(dateTime);
  const options = {
    year: "numeric",
    month: "long",
    day: "2-digit"
  };
  let dateString = date.toLocaleString("en-US", options);
  return dateString;
}

// Convert UTC DateTime string to local DateTime
// Return string with local options
function formatUtcDateTime(dateTime) {
  if (dateTime) {
    let utcDateTime = dateTime + "Z"; // Z means time is in UTC
    let localDateTime = new Date(utcDateTime);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    };
    let dateString = localDateTime.toLocaleString("en-US", options);
    return dateString.replaceAll("/", "-");
  }
}

// get period string in format MM-YYYY from int
function formatPeriod(period) {
  if (period) {
    let year = period.toString().slice(0, 4);
    let month = period.toString().slice(4, 6);
    return month + "-" + year;
  } else {
    return null;
  }
}

// get period string in format YYYY-MM from int
function getYearMonthPeriod(period) {
  if (period) {
    let year = period.toString().slice(0, 4);
    let month = period.toString().slice(4, 6);
    return year + "-" + month;
  } else {
    return null;
  }
}

// get period string in format MM-YYYY from string in format YYYY-MM
function getMonthYearFromYearMonthPeriod(period) {
  if (period) {
    let year = period.slice(0, 4);
    let month = period.slice(5, 7);
    return month + "-" + year;
  } else {
    return null;
  }
}

function formatBillingRunType(type) {
  return type.split(/(?=[A-Z])/).join(" ");
}

function insertWhitespaces(text) {
  if (text) {
    return text.replace(/[A-Z]/g, " $&").trim();
  }
}

module.exports = {
  formatMonetaryValue,
  formatMonetaryValueWithWarning,
  formatMonetaryValueNoWhitespace,
  formatDate,
  formatDateTextMonth,
  formatPeriod,
  getYearMonthPeriod,
  getMonthYearFromYearMonthPeriod,
  formatBillingRunType,
  formatUtcDateTime,
  formatMonetaryValueUnrounded,
  formatNumberUnrounded,
  insertWhitespaces,
};
